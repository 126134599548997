<ng-container>
    <app-card-detail-layout header="In Progress" 
    [isLoading]="isLoading"
    [items]="recentlyAdded"
    [filters]="filters"
    [pagination]="pagination"
    (pageChange)="onPageChange($event)"
    (applyFilter)="updateFilter($event)"
    >
        <ng-template #cardItem let-item let-position="idx">
            <app-series-card [data]="item" [libraryId]="item.libraryId" (reload)="loadPage()"></app-series-card>
        </ng-template>
    </app-card-detail-layout>
</ng-container>