<div class="card">
    <div class="overlay"  (click)="handleClick()">
      <img *ngIf="total > 0 || supressArchiveWarning" class="img-top lazyload" [src]="imageService.placeholderImage" [attr.data-src]="imageUrl" 
            (error)="imageService.updateErroredImage($event)" aria-hidden="true" height="230px" width="158px">
      <img *ngIf="total === 0 && !supressArchiveWarning" class="img-top lazyload" [src]="imageService.errorImage" [attr.data-src]="imageUrl" 
            aria-hidden="true" height="230px" width="158px">
      <div class="progress-banner" *ngIf="read < total && total > 0 && read !== (total -1)">
        <p><ngb-progressbar type="primary" height="5px" [value]="read" [max]="total"></ngb-progressbar></p>

        <span class="download" *ngIf="download$ | async as download">
          <app-circular-loader [currentValue]="download.progress"></app-circular-loader>
          <span class="sr-only" role="status">
            {{download.progress}}% downloaded
          </span>
        </span>
      </div>
      <div class="error-banner" *ngIf="total === 0 && !supressArchiveWarning">
        Cannot Read Archive
      </div>

      
      
      <div class="not-read-badge" *ngIf="read === 0 && total > 0"></div>
    </div>
    <div class="card-body" *ngIf="title.length > 0 || actions.length > 0">
      <div>
        <span class="card-title" placement="top" ngbTooltip="{{title}}" (click)="handleClick()" tabindex="0">
          <span *ngIf="isPromoted()">
            <i class="fa fa-angle-double-up" aria-hidden="true"></i>
          </span>
          <i class="fa {{utilityService.mangaFormatIcon(format)}}" aria-hidden="true" *ngIf="format != MangaFormat.UNKNOWN" title="{{utilityService.mangaFormat(format)}}"></i><span class="sr-only">{{utilityService.mangaFormat(format)}}</span>
          &nbsp;{{title}}
          <span class="sr-only">(promoted)</span>
        </span>
        <span class="card-actions float-right">
          <app-card-actionables (actionHandler)="performAction($event)" [actions]="actions" [labelBy]="title"></app-card-actionables>
        </span>
      </div>
      <a class="card-title library" [routerLink]="['/library', libraryId]" routerLinkActive="router-link-active" *ngIf="!supressLibraryLink && libraryName">{{libraryName | titlecase}}</a>
    </div>
</div>