<div class="mx-auto login">
    <div class="card p-3" style="width: 18rem;">
        <div class="logo-container">
            <img class="logo" src="assets/images/kavita-book-cropped.png" alt="Kavita logo"/>        
            <h3 class="card-title text-center">Kavita</h3>
        </div>

        <div class="card-text">
            <form [formGroup]="loginForm" (ngSubmit)="login()">
                <div class="form-group">
                    <label for="username">Username</label>
                    <input class="form-control" formControlName="username" id="username" type="text" autofocus>
                </div>
                
                <div class="form-group">
                    <label for="password">Password</label>
                    <input class="form-control" formControlName="password" id="password" type="password">
                </div>
                
                <div class="float-right">
                    <button class="btn btn-primary alt" type="submit">Login</button>
                </div>
                
            </form>
        </div>
        
    </div>
</div>